(function ($) {
  'use strict';

  window.scala.documentReady.then(function () {
    var $defaultSlider = $('.slider--default');
    var $newsSlider = $('.news-slider .owl-carousel');
    var $weekSlider =$('.flight-period_slider[data-slider="1"]');

    $defaultSlider.owlCarousel({
      items: 1,
      margin: 0,
      nav: true,
      autoplay: true,
      autoplayTimeout: 8000
    });

    if ($newsSlider.find(".new").length >= 2) {
      $newsSlider.owlCarousel({
        items: 1,
        margin: 20,
        nav: true,
        navText : ["",""],
        autoplay: true,
        autoplayTimeout: 8000
        // dotsContainer: '#dots-navigation'
      });
    } else {
      $newsSlider.removeClass("owl-carousel");
    };

    $('[data-carousel]').owlCarousel({
      items: 1,
      margin: 0,
      nav: true,
      navText : ["",""],
      autoplay: true,
      autoplayTimeout: 8000
    });


    /*
     * Slider des programmes de vol
     */

    var items = 0,
        item = 0,
        btnPrev = '.flight-program__button--prev',
        btnNext = '.flight-program__button--next';


    var btnStatus = function (event) {
      var $slider = $(event.target);
      items = event.item.count; // Number of items
      item = event.item.index; // Position of the current item

      if (item === 0) { // prev btn
        $slider.parent().prev(btnPrev).addClass('disabled');
      } else {
        $slider.parent().prev(btnPrev).removeClass('disabled');
      }

      if (item === items-1) { // next btn
        $slider.parent().next(btnNext).addClass('disabled');
      } else {
        $slider.parent().next(btnNext).removeClass('disabled');
      }
    }

    var createWeekSlider = function (elem) {
      elem.addClass('owl-carousel owl-theme');
      elem.owlCarousel({
        items: 1,
        dots: false,
        onChanged: btnStatus
      });
      elem.parent().prev(btnPrev).click(function() {
        elem.trigger('prev.owl.carousel');
      })
      elem.parent().next(btnNext).click(function() {
        elem.trigger('next.owl.carousel');
      })
    }

    $weekSlider.each(function(){
      createWeekSlider($(this));
    })

  });
}(window.jQuery.noConflict()));
